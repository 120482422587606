<template>
  <div class="inner-section application-form-wrapper">
    <bread-cumb />
    <body-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('teaGarden.tea_production_stock_in') }}</h4>
      </template>
      <template v-slot:body>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
          <b-overlay :show="loading">
            <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
              <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                  <ValidationProvider name="Year" vid="fiscal_year_id" rules="required|min_value:1">
                    <b-form-group label-for="fiscal_year_id" slot-scope="{ valid, errors }">
                      <template v-slot:label>
                        {{ $t('globalTrans.fiscal_year') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        id="fiscal_year_id"
                        v-model="formData.fiscal_year_id"
                        :options="fiscalYearList"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                        <template v-slot:first>
                          <b-form-select-option value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback d-block">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Type" vid="type" rules="required">
                    <b-form-group
                        slot-scope="{ valid, errors }"
                        label-cols-sm="12"
                        label-for="type"
                        :label="$t('teaGarden.factory_type')">
                        <b-form-radio-group
                            :disabled="isFactoryAdminCheckFactoryId() || isGardenAdminCheckGardenId() ? true : false"
                            :state="errors[0] ? false : (valid ? true : null)"
                            class="custom-control-inline-wrapper mb-0" v-model="formData.type" size="lg" :options="factoryTypeList"
                            name="radio-options">
                        </b-form-radio-group>
                        <div class="invalid-feedback d-block">
                        {{ errors[0] }}
                        </div>
                    </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4" v-if="formData.type === 2">
                  <ValidationProvider name="Factory" vid="factory_id" rules="required|min_value:1">
                    <b-form-group label-for="factory_id" slot-scope="{ valid, errors }">
                      <template v-slot:label>
                        {{ $t('teaGardenConfig.factory_name') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        :disabled="isFactoryAdminCheckFactoryId() ? true : false"
                        id="factory_id"
                        v-model="formData.factory_id"
                        :options="masterBoughtLeafFactoryList"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback d-block">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4" v-if="formData.type === 1">
                  <ValidationProvider name="Garden" vid="garden_id" rules="required|min_value:1">
                    <b-form-group label-for="garden_id" slot-scope="{ valid, errors }">
                      <template v-slot:label>
                        {{ $t('teaGardenConfig.garden_name') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        :disabled="isGardenAdminCheckGardenId() ? true : false"
                        plain
                        id="garden_id"
                        v-model="formData.garden_id"
                        :options="gardenlist"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback d-block">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                  <ValidationProvider name="Current Stock" vid="green_leaf_current_stock" :rules="{required:false}">
                    <b-form-group
                      slot-scope="{ valid, errors }"
                      label-for="green_leaf_current_stock">
                      <template v-slot:label>
                        {{ $t('teaGarden.green_leaf_current_stock') }}  ({{ $t('globalTrans.kg') }})
                      </template>
                      <b-form-input
                        disabled
                        id="green_leaf_current_stock"
                        :value="current_stock_loading ? $t('globalTrans.loading') : green_leaf_current_stock"
                        :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                  <ValidationProvider name="Green Leaf" vid="total_green_leaf" rules="required|min_value:1">
                    <b-form-group
                      slot-scope="{ valid, errors }"
                      label-for="total_green_leaf">
                      <template v-slot:label>
                        {{ $t('teaGarden.total_green_leaf') }} ({{ $t('globalTrans.kg') }})<span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        id="total_green_leaf"
                        v-model="formData.total_green_leaf"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                        :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                  <ValidationProvider name="Stock In Date" vid="stock_in_date" rules="required">
                    <b-form-group
                      class="row"
                      label-for="stock_in_date"
                      slot-scope="{ valid, errors }">
                      <template v-slot:label>
                        {{ $t('stock_management.stock_in_date') }}  <span class="text-danger">*</span>
                      </template>
                      <date-picker
                        id="stock_in_date"
                        v-model="formData.stock_in_date"
                        class="form-control"
                        :placeholder="$t('globalTrans.select_date')"
                        :state="errors[0] ? false : (valid ? true : null)"
                        :class="errors[0] ? 'is-invalid' : ''"
                        :locale="currentLocale"
                      >
                      </date-picker>
                      <div class="invalid-feedback d-block">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
              <div class="add-more-area border-top border-dark pt-3">
                <b-row>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Made Tea Type" vid="tea_type_id" rules="">
                      <b-form-group label-for="tea_type_id" slot-scope="{ valid, errors }">
                        <template v-slot:label>
                          {{ $t('teaGardenConfig.made_tea_type') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          id="tea_type_id"
                          v-model="formDataAddMore.tea_type_id"
                          :options="masterMadeTeaTypeList"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                          <template v-slot:first>
                            <b-form-select-option value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback d-block">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Stock Quantity" vid="stock_quantity" rules="">
                      <b-form-group label-for="stock_quantity" slot-scope="{ valid, errors }">
                        <template v-slot:label>
                          {{ $t('teaGarden.stock_quantity') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="stock_quantity"
                          v-model="formDataAddMore.stock_quantity"
                          :state="errors[0] ? false : (valid ? true : null)"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          @keypress="isNumber"
                          min="0"
                        >
                        </b-form-input>
                        <div class="invalid-feedback d-block">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <ValidationProvider name="Remarks" vid="remarks" rules="">
                      <b-form-group label-for="remarks" slot-scope="{ valid, errors }">
                        <template v-slot:label>
                          {{ $t('globalTrans.remarks') }}
                        </template>
                        <b-form-textarea
                          id="remarks"
                          v-model="formDataAddMore.remarks"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                        </b-form-textarea>
                        <div class="invalid-feedback d-block">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12" class="text-right">
                    <b-button type="button" size="sm" class="btn btn-success" @click="addItem">
                      <i class="ri-add-circle-fill"></i> {{ $t('globalTrans.add_more') }}
                    </b-button>
                  </b-col>
                </b-row>
                <b-table-simple bordered hover small responseive class="mt-3">
                  <b-thead>
                    <b-tr>
                      <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                      <b-th>{{ $t('teaGardenConfig.made_tea_type') }}</b-th>
                      <b-th>{{ $t('teaGarden.stock_quantity') }}</b-th>
                      <b-th>{{ $t('globalTrans.remarks') }}</b-th>
                      <b-th>{{ $t('globalTrans.action') }}</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <template v-if="formData.details.length">
                      <b-tr v-for="(item, index) in formData.details" :key="index">
                        <b-td>{{ $n(index + 1) }}</b-td>
                        <b-td>{{ getTeaTypeName(item.tea_type_id) }}</b-td>
                        <b-td>
                          <ValidationProvider name="Stock Quantity" vid="stock_quantity" rules="required|min_value:0">
                            <b-form-group label-for="stock_quantity" slot-scope="{ valid, errors }">
                              <b-form-input
                                id="stock_quantity"
                                v-model="item.stock_quantity"
                                :state="errors[0] ? false : (valid ? true : null)"
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                @keypress="isNumber"
                                min="0"
                              >
                              </b-form-input>
                              <div class="invalid-feedback d-block">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-td>
                        <b-td>
                          <ValidationProvider name="Remarks" vid="remarks" rules="">
                            <b-form-group label-for="remarks" slot-scope="{ valid, errors }">
                              <b-form-textarea
                                id="remarks"
                                v-model="item.remarks"
                                :state="errors[0] ? false : (valid ? true : null)"
                              >
                              </b-form-textarea>
                              <div class="invalid-feedback d-block">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-td>
                        <b-td>
                          <b-button type="button" size="sm" class="btn btn-danger" @click="deleteItem(index)">
<!--                            <i class="ri-delete-bin-line"></i>-->
                            <i class="ri-close-line"></i>
                          </b-button>
                        </b-td>
                      </b-tr>
                    </template>
                    <template v-else>
                      <b-tr>
                        <b-td class="text-center" colspan="5">{{ $t('globalTrans.noDataFound') }}</b-td>
                      </b-tr>
                    </template>
                    <b-tr>
                      <b-td colspan="2"><strong>{{ $t('globalTrans.grand_total') }}</strong></b-td>
                      <b-td class="text-right"><strong>{{ $n(getTotalQty()) }}</strong></b-td>
                      <b-td colspan="2"></b-td>
                    </b-tr>
                    <b-tr v-if="formData.total_green_leaf && getTotalQty() > 0">
                      <b-td colspan="2"><strong>{{ $t('teaGarden.recovery_rate') }}</strong></b-td>
                      <b-td class="text-right"><strong>{{ $n((getTotalQty() / formData.total_green_leaf) * 100) }} %</strong></b-td>
                      <b-td colspan="2"></b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </div>
              <b-row class="text-right mt-3">
                <b-col>
                    <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
                    <b-button variant="danger" class="btn-sm" @click="back">{{ $t('globalTrans.cancel') }}</b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-overlay>
        </ValidationObserver>
      </template>
    </body-card>
  </div>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { stockInStore, getFactoryGreenLeafCurrentStockApi } from '../../api/routes'
import { helpers } from '@/utils/helper-functions'
import BreadCumb from '@/components/BreadCumb.vue'
import teaGardenService from '@/mixins/tea-garden-service'
export default {
  mixins: [teaGardenService],
  name: 'Form',
  components: { BreadCumb },
  data () {
    return {
      valid: null,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      formData: {
        factory_id: null,
        garden_id: null,
        type: 2,
        fiscal_year_id: this.$store.state.currentFiscalYearId,
        stock_in_date: helpers.currentDate(),
        details: []
      },
      formDataAddMore: {
        tea_type_id: 0,
        stock_quantity: 0,
        remarks: ''
      },
      green_leaf_current_stock: 0,
      current_stock_loading: false
    }
  },
  computed: {
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    currentLocale () {
      return this.$i18n.locale
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    masterBoughtLeafFactoryList () {
      return this.$store.state.TeaGardenService.commonObj.masterBoughtLeafFactoryList.filter(item => item.status === 1 && item.factory_type_id === 2)
    },
    masterMadeTeaTypeList () {
      return this.$store.state.TeaGardenService.commonObj.masterMadeTeaTypeList.filter(item => item.status === 1)
    },
    gardenlist () {
      return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.status === 1)
    },
    factoryTypeList () {
      return [
          { value: 1, text: this.$i18n.locale === 'en' ? 'Gardens Fatory' : 'বাগানের নিজস্ব কারখানা' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Bought Leaf Factory' : 'বটলীফ কারখানা' }
      ]
    }
  },
  watch: {
    'formData.type': function (newVal, oldVal) {
      if (newVal && newVal === 1) {
        this.formData.factory_id = null
      }
      if (newVal && newVal === 2) {
        this.formData.garden_id = null
      }
      this.getFactoryGreenLeafCurrentStock()
    },
    'formData.garden_id': function (newVal, oldval) {
      if (newVal) {
        this.getFactoryGreenLeafCurrentStock()
      }
    },
    'formData.factory_id': function (newVal, oldval) {
      if (newVal) {
        this.getFactoryGreenLeafCurrentStock()
      }
    }
  },
  created () {
    if (this.$route.query.id) {
      this.getFormData(this.$route.query.id)
    }
    if (this.isGardenAdminCheckGardenId()) {
        const gardenObj = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(item => item.value === this.isGardenAdminCheckGardenId())
        if (gardenObj.has_own_garden === 1) {
          this.formData.type = 1
        } else {
          this.formData.type = 2
        }
        this.formData.garden_id = this.isGardenAdminCheckGardenId()
    }
    if (this.isFactoryAdminCheckFactoryId()) {
        this.formData.type = 2
        this.formData.factory_id = this.isFactoryAdminCheckFactoryId()
    }
    this.getFactoryGreenLeafCurrentStock()
  },
  methods: {
    async saveUpdate () {
      if (!this.formData.details.length) {
        return this.$toast.error({
          title: this.$t('globalTrans.error'),
          message: this.$t('teaGarden.select_required_item')
        })
      }
      if (this.formData.total_green_leaf > this.green_leaf_current_stock) {
        return this.$toast.error({
          title: this.$t('globalTrans.error'),
          message: this.$t('teaGarden.green_leaf_not_gather_than_current_stock')
        })
      }
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const loadingState = { loading: false, listReload: false }
      const result = await RestApi.postData(teaGardenServiceBaseUrl, stockInStore, this.formData)

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('TeaGardenService/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$router.push('tea-production-stock-in')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    isNumber (evt) {
        helpers.isNumber(evt)
    },
    back () {
      this.$router.go(-1)
    },
    getTeaTypeName (id) {
      const obj = this.$store.state.TeaGardenService.commonObj.masterMadeTeaTypeList.find(item => item.value === parseInt(id))
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    addItem () {
      if (!(this.formDataAddMore.tea_type_id && this.formDataAddMore.stock_quantity)) {
        return this.$toast.error({
          title: this.$t('globalTrans.error'),
          message: 'Please select tea type and transfer quantity'
        })
      }
      const obj = this.formData.details.find(item => item.tea_type_id === this.formDataAddMore.tea_type_id)
      if (obj !== undefined) {
        return this.$toast.error({
          title: this.$t('globalTrans.error'),
          message: 'This tea type already exists'
        })
      }
      this.formData.details.push(this.formDataAddMore)
      this.formDataAddMore = {
        tea_type_id: 0,
        stock_quantity: 0,
        remarks: ''
      }
    },
    deleteItem (index) {
      this.formData.details.splice(index, 1)
    },
    getTotalQty () {
      let totalQty = 0
      this.formData.details.forEach(item => {
        totalQty += parseFloat(item.stock_quantity)
      })
      return totalQty
    },
    async getFactoryGreenLeafCurrentStock () {
        this.current_stock_loading = true
        let result = null
        const data = {
          garden_id: this.formData.garden_id,
          factory_id: this.formData.factory_id,
          type: this.formData.type
        }
        result = await RestApi.getData(teaGardenServiceBaseUrl, getFactoryGreenLeafCurrentStockApi, data)
        if (result.success) {
          this.green_leaf_current_stock = result.data.stock_quantity
        } else {
          this.green_leaf_current_stock = 0
        }
        this.current_stock_loading = false
    }
  }
}
</script>
